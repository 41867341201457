<template>
  <div v-if="rsCollection && rsCollectionTemp">
    <header>
      <div class="app-navbar">
        <div class="app-navbar__wrapper bg-uilight shadow">
          <BaseTableBlock>
            <template v-slot:row-1>
              <div class="w-full">
                <BaseSelect
                  :name="'pageView'"
                  :id="'pageView'"
                  :options="pageViewOptions"
                  :value="pageView"
                  @selectionChange="pageView = $event"
                ></BaseSelect>
              </div>
              <select
                @input="setLanguage($event.target.value)"
                class="app-select__select"
                :placeholder="'Select language'"
                style="box-shadow: none"
              >
                <option
                  v-for="(lang, i) in rsCollection?.locales"
                  :key="`Lang${i}`"
                  :value="lang.code"
                  v-bind:selected="language === lang.code"
                >
                  {{ lang.label }}
                </option>
              </select>
            </template>
          </BaseTableBlock>
        </div>
      </div>
    </header>
    <div v-if="rsCollectionTemp.locales" class="p-5">
      <div v-if="pageView === 'home'">
        <BaseInput
          class="mt-5"
          :label="$t('Set your title')"
          :id="'title'"
          :name="'title'"
          :error="titleError"
          :warning="titleLimitReached"
          :maxLength="50"
          :warningText="$t('You have reached the limit of 50 characters')"
          :errorText="
            $t('Please choose a title with a maximum length of 50 characters')
          "
          :value="rsCollectionTemp.title"
          @input="rsCollectionTemp.title = $event.target.value"
        ></BaseInput>
        <div class="app-select mt-5">
          <label class="app-select__label">{{ $t('Description') }}</label>

          <client-only
            ><tiptap-editor
              :value="
                rsCollectionTemp.locales.find(obj => obj.code == language)
                  .translations.home.content[0]
              "
              @inputHtml="updateContent"
              :content="
                rsCollectionTemp.locales.find(obj => obj.code == language)
                  .translations.home.content[0]
              "
              :showSave="false"
          /></client-only>
        </div>

        <label class="app-select__label mt-5">{{
          $t('Background Image')
        }}</label>
        <div class="flex flex-row mt-1">
          <div class="bg-indigo-300">
            <img
              class="object-cover h-48 w-96"
              :src="getIIIFImage(rsCollection, 'background') || noDocumentImage"
            />
          </div>
          <div class="flex flex-col w-full">
            <BaseButton
              as-label
              :for="'UploadImages'"
              class="m-2"
              :label="'Upload image'"
              @click="emitUploadSlideOver('background')"
              :type="'primary'"
            ></BaseButton>
            <BaseButton
              :disabled="!getIIIFImage(rsCollection, 'background')"
              class="m-2"
              :label="'Remove'"
              @click="deleteImage(rsCollectionTemp, 'background')"
              :type="'secondary'"
            ></BaseButton>
          </div>
        </div>
      </div>
      <div v-if="pageView === 'explore'">
        <div class="form-control mt-5" v-if="rsCollectionTemp.config">
          <label class="label cursor-pointer">
            <span class="">{{ $t('Browse tags') }}</span>
            <input
              v-model="hasTags"
              type="checkbox"
              class="toggle toggle-primary"
              :checked="hasTags"
            />
          </label>
          <label class="label">
            <span class="">{{ $t('Tags') }}</span>
          </label>
          <TagFilter
            :initTags="config?.tagTypes"
            :collId="collId"
            @selected="setTags"
          />
          <hr class="my-8 border-gray opacity-50" />

          <label class="label cursor-pointer">
            <span class="">{{ $t('Hierarchy') }}</span>
            <input
              v-model="hasHierarchy"
              type="checkbox"
              class="toggle toggle-primary"
              :checked="hasHierarchy"
            />
          </label>
        </div>
      </div>
      <div v-if="pageView === 'search'">
        <label class="label">
          <span class="">{{ $t('Filter') }}</span>
        </label>
        <MetaFilter @filter="setFilter" :initFilter="initMeta" />

        <div v-if="$features.newSitesFilter">
          <hr class="my-8 border-gray opacity-50" />

          <label class="label">
            <span class="">{{ $t('Year Filter') }}</span>
          </label>
          <div class="flex flex-col gap-2">
            <MetaFilter
              :initFilter="rangeFields"
              :propFacet="yearFields"
              @filter="setYearField"
            />
            <div
              v-for="(year, index) in this.rsCollectionTemp?.config?.range"
              :key="index"
              class="flex flex-col gap-2"
            >
              <BaseInput
                :label="$t('From range for') + ': ' + $t(year.field)"
                :type="'Number'"
                :min="0"
                :max="new Date().getFullYear()"
                :value="year.from"
                @input="year.from = $event.target.value"
              ></BaseInput>
              <BaseInput
                :label="$t('To range for') + ': ' + $t(year.field)"
                :type="'Number'"
                :min="0"
                :max="new Date().getFullYear()"
                :value="year.to"
                @input="year.to = $event.target.value"
              ></BaseInput>
            </div>
          </div>
        </div>
      </div>
      <div v-if="pageView === 'about' || pageView.split('/')[0] === 'content'">
        <SitesAboutSettings
          :rsCollectionTemp="rsCollectionTemp"
          :translation="
            pageView === 'about'
              ? rsCollectionTemp.locales.find(obj => obj.code == language)
                  ?.translations.about
              : rsCollectionTemp?.locales.find(obj => obj.code == language)
                  ?.translations?.additionalPages[findContent(contentName)]
          "
          @upload-slide-over="emitUploadSlideOver"
          :pageView="pageView"
        ></SitesAboutSettings>
      </div>
    </div>
  </div>
</template>

<script>
import noDocumentImage from '~/assets/images/no-document.png'
// Set about content alternately, Text + Image the Text + Text with background color

import TiptapEditor from '~/components/Sites/TipTapEditor.vue'
import TagFilter from '~/components/Sites/DraftTagFilter.vue'
import MetaFilter from '~/components/Sites/DraftMetaFilter.vue'
import SitesUploadImages from '~/components/Sites/UploadImages.vue'

import { Editor } from '@tiptap/vue-3'
import draggable from 'vuedraggable'

import enJson from '~/locales/sites/en.json'

import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
import languageData from '~/assets/language-codes.json'

export default {
  components: {
    Editor,
    TiptapEditor,
    TagFilter,
    MetaFilter,
    draggable,
  },
  setup() {
    const { userProfile } = useKeycloak()
    const store = useSiteStore()
    const sitesApi = useSites()
    return {
      userProfile,
      store,
      sitesApi,
      noDocumentImage,
    }
  },
  computed: {
    ...mapState(useSiteStore, ['rsCollection', 'language', 'unsavedChanges']),
    pageViewOptions() {
      let arr = [
        { name: this.$t('Home'), value: 'home' },
        { name: this.$t('About'), value: 'about' },
        { name: this.$t('Explore'), value: 'explore' },
        { name: this.$t('Search'), value: 'search' },
      ]
      if (this.rsCollectionTemp?.config?.additionalPages) {
        this.rsCollectionTemp.config.additionalPages.forEach(page => {
          arr.push({ name: page.label, value: 'content/' + page.label })
        })
      }
      return arr
    },

    slideRange: {
      get() {
        return [this.from, this.to]
      },
      set(value) {
        const [from, to] = value
        this.from = from
        this.to = to
      },
    },

    currentLocale() {
      let lang = this.$i18n.locales
        .filter(i => i.code === this.$i18n.locale)
        .pop()

      if (!languageData[0][lang?.name]) {
        lang = { code: 'en', iso: 'en', name: 'English', label: 'EN' }
      }
      return lang
    },
    userId() {
      return this.userProfile?.UserId
    },
    searchPageRoute() {
      return {
        name: 'search',
        query: this.searchQuery,
      }
    },
    view() {
      if (this.$route.query.editView) {
        return this.$route.query.editView
      }
      return 'custom'
    },
    collId() {
      return this.rsCollection?.colId
    },
    url() {
      return this.$route.params.url
    },
    contentName() {
      return this.$route.params.contentName
    },
    rangeFields() {
      if (this.rsCollectionTemp?.config?.range) {
        let range = this.rsCollectionTemp.config.range.map(item => {
          item.name = item.lable
          item.value = item.field
          return item
        })
        return range
      }
    },
    hasTags: {
      get() {
        return this.rsCollectionTemp.config.hasTags || false // map undefined to false
      },
      set(value) {
        this.rsCollectionTemp = {
          ...this.rsCollectionTemp,
          config: {
            ...this.rsCollectionTemp?.config,
            hasTags: value,
          },
        }
      },
    },
    hasHierarchy: {
      get() {
        return this.rsCollectionTemp.config.hasHierarchy || false // map undefined to false
      },
      set(value) {
        this.rsCollectionTemp = {
          ...this.rsCollectionTemp,
          config: {
            ...this.rsCollectionTemp?.config,
            hasHierarchy: value,
          },
        }
      },
    },
    hierarchyLevels: {
      get() {
        return this.rsCollectionTemp.config.hierarchyLevels || 1 // map undefined to false
      },
      set(value) {
        this.rsCollectionTemp = {
          ...this.rsCollectionTemp,
          config: {
            ...this.rsCollectionTemp?.config,
            hierarchyLevels: value,
          },
        }
      },
    },
    initMeta() {
      if (this.rsCollectionTemp?.config?.meta) {
        return this.rsCollectionTemp.config.meta
          .filter(item => item !== 'f_title')
          .map(x => ({
            name: x,
            value: x,
          }))
      }
    },
  },
  watch: {
    '$route.fullPath': {
      handler(newPath) {
        if (!this.isProgrammaticChange) {
          this.setPageView(newPath)
        }
        this.isProgrammaticChange = false
      },
      immediate: true,
    },
    rsCollection(value) {
      this.rsCollectionTemp = JSON.parse(JSON.stringify(value))

      let selectedLocale = this.rsCollectionTemp.locales.find(
        obj => obj.code === this.language
      )

      if (!selectedLocale && this.rsCollectionTemp.locales.length > 0) {
        selectedLocale = this.rsCollectionTemp.locales[0]
      }

      if (selectedLocale && !selectedLocale.translations?.about?.sections) {
        selectedLocale.translations.about.sections = [
          {
            image: '',
            heading: 'Section heading',
            content:
              'This paragraph contains formatted text which can be customized by the user.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
            layout: 'layout-1',
          },
        ]
      }

      if (selectedLocale?.translations?.about?.sections) {
        selectedLocale.translations.about.sections =
          selectedLocale.translations.about.sections.map((section, index) => {
            section.index = index
            return section
          })
      }
    },

    languageArr(value) {
      value?.forEach(lang => {
        if (
          !this.rsCollectionTemp.locales.map(l => l.code).includes(lang.alpha2)
        ) {
          this.rsCollectionTemp?.locales.push({
            label: lang[this.currentLocale.name],
            code: lang.alpha2,
            translations: enJson,
          })
        }
      })
      let filtedLanguage = this.rsCollectionTemp?.locales?.filter(lang =>
        value.map(l => l.alpha2).includes(lang.code)
      )
      if (this.rsCollectionTemp?.locales) {
        this.rsCollectionTemp.locales = filtedLanguage
      }
    },
    rsCollectionTemp: {
      handler(val) {
        if (val.title.length > 50 || val.title.length < 1) {
          this.titleError = true
        } else {
          this.titleError = false
        }
        if (val.title.length === 50) {
          this.titleLimitReached = true
        } else {
          this.titleLimitReached = false
        }

        const locale = val.locales.find(obj => obj.code === this.language)
        if (locale) {
          const aboutTranslations = locale.translations.about
          if (
            aboutTranslations.title.length > 50 ||
            aboutTranslations.title.length < 1
          ) {
            this.headingError = true
          } else {
            this.headingError = false
          }
          if (aboutTranslations.title.length === 50) {
            this.headingLimitReached = true
          } else {
            this.headingLimitReached = false
          }
        }

        this.store.update({
          rsCollection: JSON.parse(JSON.stringify(val)),
        })
        val = {}
      },
      deep: true,
    },

    getItems(option) {
      let items = option[Object.keys(option)[0]]
      const formattedItems = items.map(item => `${item.value}`)
      return formattedItems
    },
    pageView(newVal) {
      const query = this.$route.query

      switch (newVal) {
        case 'home':
          this.$router.push({ path: '/sites/' + this.url, query: query })
          break
        case 'about':
          this.$router.push({
            path: '/sites/' + this.url + '/about',
            query: query,
          })
          break
        case 'explore':
          this.$router.push({
            path: '/sites/' + this.url + '/browse',
            query: query,
          })
          break
        case 'search':
          this.$router.push({
            path: '/sites/' + this.url + '/search',
            query: query,
          })
          break
        default:
          if (newVal.split('/')[0] === 'content') {
            this.$router.push({
              path: '/sites/' + this.url + '/' + newVal,
            })
          } else {
            // this.$router.push({ path: '/', query: query })
          }
      }
    },
  },
  data() {
    return {
      term: '',
      queryTerm: '',
      title: ' ',
      description: '',
      additionalPage: '',
      aboutText: '',
      headingError: false,
      headingLimitReached: false,
      titleError: false,
      titleLimitReached: false,
      imageUrl: '',
      logoUrl: '',
      customUrl: '',
      editor: null,
      rsCollectionTemp: null,
      open: true,
      config: {},
      locales: {},
      from: 0,
      to: 2020,
      languageArr: [],
      filteredLanguages: [],
      pageView: 'home',
      isProgrammaticChange: false,
      yearFields: [
        { value: 'From', name: 'createdFrom' },
        { value: 'To', name: 'createdTo' },
      ],
    }
  },
  created() {
    this.$bus.on('saveChanges', this.save)
    this.$bus.on('saveLocales', this.saveLocales)
  },
  destroyed() {
    this.$bus.off('saveChanges', this.save)
    this.$bus.off('saveLocales', this.saveLocales)
  },
  async mounted() {
    try {
      this.rsCollectionTemp = JSON.parse(JSON.stringify(this.rsCollection))
      this.languageArr = this.rsCollectionTemp?.locales?.map(lang => ({
        English: lang.label,
        alpha2: lang.code,
      }))
      await this.load()
      // this.setPageView()
    } catch (err) {}
  },
  methods: {
    gotoSearch() {
      this.$router.push(
        this.localePath({
          path: '/sites/' + this.collId + '/search',
          query: { term: this.queryTerm },
        })
      )
    },
    setPageView(newPath) {
      let newPageView = ''

      if (newPath.includes('about')) {
        newPageView = 'about'
      } else if (newPath.includes('browse')) {
        newPageView = 'explore'
      } else if (newPath.includes('search')) {
        newPageView = 'search'
      } else if (newPath.includes('content')) {
        newPageView = 'content/' + this.contentName
      } else {
        const pattern = new RegExp(`/sites/${this.$route.params.url}/?$`)
        if (pattern.test(newPath)) {
          newPageView = 'home'
        }
      }

      if (this.pageView !== newPageView) {
        this.pageView = newPageView
        this.isProgrammaticChange = true
        const query = this.$route.query
        this.$router.push({ path: newPath, query: query })
      }
    },
    languageKeys() {
      let arr = this.rsCollectionTemp.locales.map(lang => ({
        name: Object.keys(lang)[0],
      }))
      return arr
    },
    emitUploadSlideOver(type, section) {
      if (type && section) {
        type = type.replace(/\s+/g, '_')

        section.image = type
        this.save()
      }
      const slideOver = {
        slideOver: 'UploadImages',
        slideContent: {
          component: SitesUploadImages,
          props: {
            locales: this.rsCollectionTemp.locales,
            fileName: type,
          },
          data: {},
        },
      }
      this.$bus.emit('slideOver', slideOver)
    },
    saveLocales(locales) {
      this.rsCollectionTemp.locales = locales
      this.save()
    },
    setLanguage(value) {
      if (value) {
        this.store.setLanguage(value)
      }
    },
    async save() {
      try {
        let data = await this.sitesApi.updateRsLiteCollection({
          sites: this.rsCollectionTemp,
          customUrl: this.rsCollectionTemp.url,
        })
        const notification = {
          message: this.$t('Updated Sites'),
          type: 'success',
        }
        this.$bus.emit('notification', notification)
        // this.store.setSites(this.rsCollectionTemp)
      } catch (err) {
        const notification = {
          message: this.$t('Updating Sites failed'),
          type: 'danger',
        }
        this.$bus.emit('notification', notification)
      }
    },
    async deleteImage(item, type) {
      const foundImage = item.images.find(i => i.type === type)

      try {
        let data = await this.sitesApi.deleteReadSearchImage({
          fileName: foundImage.name + '.' + foundImage.extension,
          customUrl: this.rsCollectionTemp.url,
        })
        const index = item.images.findIndex(i => i.type === type)
        if (index !== -1) {
          item.images.splice(index, 1)
        }
        this.store.update({
          rsCollection: JSON.parse(JSON.stringify(this.rsCollectionTemp)),
        })
        this.save()
        const notification = {
          message: this.$t('Deleted image'),
          type: 'success',
        }
        this.$bus.emit('notification', notification)
        this.$bus.emit('imageReload')
      } catch (err) {
        const notification = {
          message: this.$t('Image deletion failed'),
          type: 'danger',
        }
        this.$bus.emit('notification', notification)
      }
    },
    getIIIFImage(item, type) {
      if (item.images && item.images.find(i => i.type === type)) {
        let image = item.images.find(image => image.type === type)
        if (image) {
          return (
            'https://s3.readcoop.eu/api-sites-test/' +
            image.name +
            '.' +
            image.extension
          )
        }
        // return `http://localhost:8080/rsCollection/${item.colId}/image/background`
      }
      return null
    },
    updateContent(newContent) {
      const locale = this.rsCollectionTemp.locales.find(
        obj => obj.code == this.language
      )
      if (locale) {
        locale.translations.home.content[0] = newContent
      }
    },

    setTags(value) {
      if (!this.rsCollectionTemp.config) {
        this.rsCollectionTemp.config = {}
      }
      if (!this.rsCollectionTemp.config.tagTypes) {
        this.rsCollectionTemp.config.tagTypes = []
      }
      this.rsCollectionTemp.config.tagTypes = value.map(tag => {
        if (typeof tag === 'object' && tag.value) {
          return tag.value
        } else {
          return tag
        }
      })
    },
    setFilter(value) {
      if (!this.rsCollectionTemp.config) {
        this.rsCollectionTemp.config = {}
      }
      if (!this.rsCollectionTemp.config.meta) {
        this.rsCollectionTemp.config.meta = []
      }
      this.rsCollectionTemp.config.meta = value.map(t => t.name)
    },
    getFlag(code) {
      if (code == 'en') {
        return 'gb'
      }
      return code
    },
    getFilteredLanguage(text) {
      this.filteredLanguages = languageData.filter(option => {
        var values = Object.values(option)
        var flag = false
        values.forEach(val => {
          if (val.toLowerCase().indexOf(text.toLowerCase()) >= 0) {
            flag = true
            return
          }
        })
        if (flag) return option
      })
    },
    findContent(name) {
      let found = this.rsCollectionTemp.config.additionalPages.find(
        obj => obj.label === name
      )
      return found?.key
    },
    getIndex(value) {
      return this.rsCollectionTemp.locales
        .find(obj => obj.code == this.language)
        .translations.about.sections.findIndex(
          section => section.id === value.id
        )
    },
    setYearField(value) {
      //check if all values exist in this.rsCollection.config.range
      if (!this.rsCollectionTemp?.config) {
        this.rsCollectionTemp.config = {}
      }
      if (!this.rsCollectionTemp.config.range) {
        this.rsCollectionTemp.config.range = []
      }
      this.rsCollectionTemp.config.range = value.map(t => {
        let found = this.rsCollectionTemp.config.range.find(
          x => x.field === t.name
        )
        if (found) {
          return {
            field: t.name,
            from: found.from,
            to: found.to,
            type: 'date',
            lable: t.name,
          }
        }
        return {
          field: t.name,
          from: this.from,
          to: this.to,
          type: 'date',
          lable: t.name,
        }
      })
    },
    async load() {
      let data = this.rsCollectionTemp
      try {
        if (data) {
          this.title = data.title
          this.aboutText = data.aboutText
          this.description = data.description

          if (data.config) {
            this.config = data.config
          }
          if (data.locales) {
            this.locales = data.locales
            this.language = this.locales[0].code
          }
          if (this.editor) {
            this.editor.commands.setContent('<p>' + this.description + '</p>')
          }
        }
      } catch (err) {
        this.$sentry.captureException(err)
      }
    },
  },
}
</script>
