<template>
  <div class="app-slide-over__wrapper">
    <div class="app-slide-over__header">
      <h5 class="app-title">{{ $t('Site Members') }}</h5>
    </div>
    <div class="app-slide-over__body">
      <p class="text-primary mr-4">
        {{
          $t(
            'Invite others to your site to collaborate. An invitation is specific to an email address.'
          )
        }}
      </p>
      <div class="flex gap-2 mb-3 justify-end">
        <BaseButton
          v-if="$features.inviteLinkSitesOrga"
          small
          :symbol="'link'"
          label="Share link"
          type="secondary"
          @click="openShareLinkModal()"
        ></BaseButton>
        <BaseButton
          small
          :symbol="'person_add'"
          :label="'Invite members'"
          @click="openInviteModal()"
        ></BaseButton>
      </div>

      <BaseTabs :fullwidth="true" :initTabIndex="tabIndex" :tabs="tabs">
        <template #tab1>
          <BaseTable :data="userList" :columns="columns" v-if="userList">
            <template #role-field="{ row, column }">
              <span>{{
                row['role'] === ROLE_SITE_CONTRIBUTOR
                  ? 'Contributor'
                  : row['role']
              }}</span>
            </template>
            <template #action-field="{ row, column }">
              <div
                v-if="
                  canSetToOwner(row) ||
                  canLeaveCollection(row) ||
                  canRemoveFromCollection(row)
                "
                class="app-card__dropdown app-card__dropdown--left content-center max-h-8"
              >
                <label tabindex="0" class="app-card__dropdown__btn !pl-0">
                  <i
                    class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
                  ></i>
                </label>
                <ul
                  tabindex="0"
                  class="app-card__dropdown__menu dropdown-content app-card__dropdown__menu--large"
                >
                  <li v-if="canSetToOwner(row)">
                    <span @click="addOrModifyUser(row, ROLE_OWNER)">
                      <span>{{ $t('Make Owner') }}</span>
                    </span>
                  </li>
                  <!-- <li v-if="canSetToEditor(row)">
                    <span @click="addOrModifyUser(row, ROLE_EDITOR)">
                      <span>{{ $t('Set to Contributor') }}</span>
                    </span>
                  </li> -->
                  <hr
                    v-if="
                      permissions.mySiteRole.isOwner && row.role !== 'Owner'
                    "
                    class="w-full border-gray-50 my-1"
                  />
                  <li v-if="canLeaveCollection(row)">
                    <span @click="removeFromSites(row)">
                      <span class="text-danger">{{ $t('Leave Site') }}</span>
                    </span>
                  </li>
                  <li v-if="canRemoveFromCollection(row)">
                    <span @click="removeFromSites(row)">
                      <span class="text-danger">{{
                        $t('Remove from Site')
                      }}</span>
                    </span>
                  </li>
                </ul>
              </div>
            </template>
          </BaseTable>
          <BasePagination
            v-if="totalUsers > itemsPerPageUsers"
            :total="totalUsers"
            :current.sync="currentPageUsers"
            :per-page="itemsPerPageUsers"
            @changePage="currentPageUsers = $event"
            :key="itemsPerPageUsers"
          ></BasePagination>
        </template>
        <template #tab2>
          <BaseTable
            :data="openInvitations"
            :columns="columnsinvitations"
            v-if="!isLoading && openInvitations?.length > 0"
          >
            <template #invitee-field="{ row, column }">
              <span v-if="row['inviteeEmail'] !== null">{{
                row['inviteeEmail']
              }}</span>
              <span
                v-else-if="row['inviteeEmail'] === null"
                @click="copyInviteLink(row['inviteId'])"
                class="cursor-pointer text-primary"
                >{{ $t('Invite link') }}
                <i class="ml-1 mdi mdi-content-copy cursor-pointer"></i>
              </span>
            </template>
            <template #state-field="{ row, column }">
              <span
                ><BaseBadge
                  class="!m-0"
                  rounded
                  :label="row['state']"
                  :type="row['state'] === 'PENDING' ? 'notification' : 'danger'"
                ></BaseBadge
              ></span> </template
            >]
            <template #role-field="{ row, column }">
              <span>{{
                row['role'] === ROLE_VIEWER
                  ? 'Viewer'
                  : row['role'] === ROLE_MANUAL_TRANSCRIBER
                  ? 'Manual Transcriber'
                  : row['role']
              }}</span>
            </template>
            <template #action-field="{ row, column }">
              <div
                class="app-card__dropdown app-card__dropdown--left content-center max-h-8"
              >
                <label tabindex="0" class="app-card__dropdown__btn !pl-0">
                  <i
                    class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
                  ></i>
                </label>
                <ul
                  tabindex="0"
                  class="app-card__dropdown__menu dropdown-content"
                >
                  <li>
                    <span @click="revokeInvitation(row)">
                      <span class="text-danger">{{
                        $t('Revoke Invitation')
                      }}</span>
                    </span>
                  </li>
                </ul>
              </div>
            </template>
          </BaseTable>
          <div class="content-center" v-if="isLoading">
            <TailwindComponentsLoading medium></TailwindComponentsLoading>
          </div>
          <div
            v-if="!isLoading && openInvitations.length === 0"
            class="bg-gray-50 px-10 py-16 rounded"
          >
            <p class="text-center">
              {{ $t('There are no pending invitations') }}
            </p>
          </div>
          <BasePagination
            v-if="totalInvitations > itemsPerPageInvitation"
            :total="totalInvitations"
            :current.sync="currentPageInvitation"
            :per-page="itemsPerPageInvitation"
            @changePage="currentPageInvitation = $event"
            :key="itemsPerPageInvitation"
          ></BasePagination>
        </template>
      </BaseTabs>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
import ShareLink from '~/components/Modals/ShareLink.vue'

export default {
  setup() {
    const userApi = useUser()
    const siteStore = useSiteStore()
    const sitesApi = useSites()
    const invitationsApi = useInvitations()
    const permissions = usePermissions()
    const { userProfile } = useKeycloak()
    return {
      userApi,
      siteStore,
      sitesApi,
      permissions,
      userProfile,
      invitationsApi,
    }
  },
  props: {
    siteId: {
      type: String,
    },
  },
  data() {
    return {
      currentPageInvitation: 1,
      itemsPerPageInvitation: 12,
      totalInvitations: null,
      openInvitations: null,
      addUser: false,
      isReady: false,
      isLoading: false,
      isError: false,
      tabIndex: 0,
      activeStep: 0,
      selectedUser: null,
      removePackage: null,
      userLable: 'Select User',
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      inputError: '',
      helpText: '',
      total: null,
      userList: [],
      foundUsers: null,
      itemsPerPage: 5,
      columnsinvitations: [
        {
          field: 'invitee',
          label: this.$t('Open Invitations'),
          display: true,
          searchable: true,
          slotName: 'invitee-field',
          custom: true,
        },
        {
          field: 'state',
          label: this.$t('State'),
          display: true,
          custom: true,
          slotName: 'state-field',
        },
        {
          field: 'role',
          label: this.$t('Role'),
          display: true,
          custom: true,
          slotName: 'role-field',
        },
        {
          field: 'action',
          label: this.$t('Action'),
          custom: true,
          display: true,
          slotName: 'action-field',
        },
      ],
      columns: [
        {
          field: 'userName',
          label: this.$t('User name'),
          searchable: true,
          display: true,
          dropdown: false,
          header1: 'firstname',
          header2: 'lastname',
        },
        {
          field: 'role',
          label: this.$t('Role'),
          searchable: false,
          display: true,
          options: ['Editor', 'Owner'],
          custom: true,
          slotName: 'role-field',
        },
        {
          field: 'action',
          label: this.$t('Action'),
          custom: true,
          display: true,
          slotName: 'action-field',
        },
      ],
      userLoading: false,
      invalidEmail: false,
      rsCollectionTemp: null,
    }
  },
  computed: {
    ...mapState(useSiteStore, ['rsCollection', 'language', 'unsavedChanges']),
    isAdmin() {
      return this.userProfile?.IsAdmin
    },
    tabs() {
      let tabs = [
        {
          title: this.$t('Site Members') + ' (' + this.userList?.length + ')',
          id: 'tab1',
        },
        { title: this.$t('Invitations'), id: 'tab2' },
      ]
      return tabs
    },
  },
  watch: {
    currentPageInvitation() {
      this.getOpenInvitations()
    },
    rsCollectionTemp: {
      handler(val) {
        this.siteStore.update({
          rsCollection: JSON.parse(JSON.stringify(val)),
        })
      },
      deep: true,
    },
  },
  created() {
    this.$bus.on('reload-members', this.reloadMembers)
  },
  destroyed() {
    this.$bus.off('reload-members', this.reloadMembers)
  },
  async mounted() {
    this.rsCollectionTemp = JSON.parse(JSON.stringify(this.rsCollection))

    await this.fetchUserList()
    await this.getOpenInvitations()

    this.isReady = true
  },

  methods: {
    async revokeInvitation(member) {
      await this.invitationsApi.deleteSitesInvitation({
        sitesId: this.siteId,
        inviteId: member.inviteId,
      })
      await this.getOpenInvitations()
    },
    async reloadMembers(index = 1) {
      this.isLoading = true
      this.tabIndex = index
      this.permissions.setSitePermissions(this.rsCollectionTemp)
      await this.getOpenInvitations()

      this.$nextTick(() => {
        this.isLoading = false
      })
    },
    async getOpenInvitations() {
      this.isLoading = true

      try {
        let response = await this.invitationsApi.fetchSitesInvitations({
          sitesId: this.siteId,
          size: this.itemsPerPageInvitation,
          page: this.currentPageInvitation - 1,
        })
        this.totalInvitations = response?.total
        this.openInvitations = response?.list?.filter(
          i => i.state !== 'ACCEPTED'
        )
      } catch (err) {
        this.$sentry.captureException(err)
      }

      this.$nextTick(() => {
        this.isLoading = false
      })
    },
    canSetToOwner(user) {
      return (
        (this.permissions.sites[this.siteId]?.manageUsers || this.isAdmin) &&
        user['role'] !== ROLE_SITE_OWNER
      )
    },
    canSetToEditor(user) {
      return (
        (this.permissions.sites[this.siteId]?.manageUsers || this.isAdmin) &&
        user['role'] !== ROLE_SITE_CONTRIBUTOR
      )
    },
    // Owners can not leave the collection, they need to asign another owner first
    canLeaveCollection(user) {
      return (
        user.userId === this.userProfile.UserId &&
        !this.permissions.mySiteRole.isOwner
      )
    },
    canRemoveFromCollection(user) {
      return (
        this.permissions.sites[this.siteId]?.manageUsers &&
        user.userId !== this.userProfile.UserId &&
        !(user['role'] === ROLE_SITE_OWNER)
      )
    },
    addOrModifyUser(user, role) {
      if (role) {
        user.role = role
      }
      if (user.role === 'Owner') {
        this.userList.forEach(u => {
          if (u !== user) {
            u.role = 'Editor'
          }
        })
        let userListTemp = [...this.userList]
        userListTemp = userListTemp.filter(u => u.role !== 'Owner')
        this.rsCollectionTemp.userId = user.userId
        this.rsCollectionTemp.additionalUsers = [...userListTemp]
      }

      this.updateSites()
    },
    openInviteModal() {
      this.$emit('openSiteInviteModal')
      this.$bus.emit('slideOverClose')
    },
    async fetchUserList() {
      this.userList = [...this.rsCollectionTemp.additionalUsers]
      try {
        const owner = await this.userApi.fetchUsersById({
          userId: this.rsCollection.userId,
        })
        owner.role = 'Owner'

        this.userList.push(owner)
      } catch (err) {
        this.$sentry.captureException(err)
      }
    },

    async removeFromSites(user) {
      if (user.role === 'Editor') {
        const index = this.userList.findIndex(u => u === user)

        if (index !== -1) {
          this.userList.splice(index, 1)
        }

        let userListTemp = [...this.userList]
        userListTemp = userListTemp.filter(u => u.role !== 'Owner')

        this.rsCollectionTemp.additionalUsers = [...userListTemp]
        await this.updateSites()
      } else {
        this.$bus.emit('notification', {
          message: this.$t('Owner cannot be removed'),
          type: 'error',
        })
      }
    },
    async updateSites() {
      await this.sitesApi.updateRsLiteCollection({
        sites: this.rsCollectionTemp,
        customUrl: this.rsCollectionTemp.url,
      })
      this.$emit('userChange', this.rsCollectionTemp.additionalUsers)
      await this.reloadMembers(0)
      this.$bus.emit('notification', {
        message: this.$t('Updated Sites'),
        type: 'success',
        position: 'center',
      })
    },
    selected(item) {
      this.selectedUser = item
      this.activeStep = 1
      this.userLable = item.userName
    },

    isOnlyOwner(user) {
      return (
        this.userList.filter(u => u.role === 'Owner').length === 1 &&
        user.role === 'Owner'
      )
    },
    openShareLinkModal() {
      const modal = {
        modalContent: {
          component: ShareLink,
          props: { inviteTo: 'site', siteId: this.siteId },
        },
      }
      this.$bus.emit('open-modal', {
        modal: modal,
        props: { isMedium: true, maxFit: true },
      })
    },
    async copyInviteLink(inviteId) {
      try {
        await navigator.clipboard.writeText(
          `${window.location.origin}/invitations/${inviteId}`
        )
        this.$bus.emit('notification', {
          message: this.$t('Link copied to clipboard'),
          type: 'success',
        })
      } catch (err) {
        this.$bus.emit('notification', {
          message: this.$t('Could not copy link to clipboard'),
          type: 'error',
        })
      }
    },
  },
}
</script>
